.SwitchRoot {
  padding: 0;
  border: none;
  width: 42px;
  height: 25px;
  background-color: #3E3F45;
  border-radius: 9999px;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: all 250ms;
}

.SwitchRoot[data-state='checked'] {
  background-color: #727272;
}

.SwitchThumb {
  display: block;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 9999px;
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;
}
.SwitchThumb[data-state='checked'] {
  transform: translateX(19px);
}

.Label {
  color: white;
  font-size: 15px;
  line-height: 1;
}