// NUMBER FILTER BOX
.number-filter-box {
  background-color: $background_color;
  width: 300px;
}

.number-filter-box-item {
  width: 100%;
  margin-bottom: 5px;
  padding: 16px 0;
  border-top: 1px solid $border-color;

  &:first-child {
    padding-top: 0;
    border-top: none;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.number-filter-title {
  font-size: size(14);
  letter-spacing: 0.19px;
  color: $text_color;
  font-weight: 400;
  line-height: size(19);
  margin-bottom: size(11);
}

.numbers-value {
  min-width: 45px;
  font-size: size(14);
  letter-spacing: 0.19px;
  color: $text_color;
  font-weight: 700;
  line-height: size(19);
}
