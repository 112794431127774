.PopoverContent {
  border-radius: 24px;
  padding: 16px;
  width: 200px;
  //margin-right: 16px;
  background-color: #141414;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.7);

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  font-size: size(14);
  z-index: 99999;
  outline: none;
}

.PopoverContent[data-state='open'][data-side='top'] {
  animation-name: slideDownAndFade;
}

.PopoverContent[data-state='open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}

.PopoverContent[data-state='open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}

.PopoverContent[data-state='open'][data-side='left'] {
  animation-name: slideRightAndFade;
}

.PopoverArrow {
  fill: #141414;
}

.PopoverContent.search {
  position: absolute;
  width: 620px;
  max-width: calc(100vw - 30px);
  left: 50%;
  border-radius: 12px;
  transform: translate(-50%, 5px);
  max-height: calc(100vh - 90px);
  overflow: auto;
  padding: 16px 10px;
  background-color: #25272E;
  border: 1px solid #404040;
  box-shadow: none;

  @media screen and (min-width: 992px) {
    left: 0;
    transform: translate(0, 10px);
    padding: 20px;
  }
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.user-menu__item {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    color: $brand-color;
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
