@import "Comment";
@import "Buttons";
@import "Switch";
@import "Dialogs";
@import "Checkbox";
@import "Popover";
@import "Input";
@import "HoverableContentPoster";
@import "Filter";
@import "Search";
@import "numberFilterBox";
@import "genreSelectionBox";
@import "sortSelectionBox";
@import "ListCard";
@import "ListOfWeek";
@import "ContentListSlider";
@import "LiveCategoryCard";
@import "LiveCategoryList";
@import "ChannelLogo";
@import "RecentViews";
@import "CompanyLogo";
@import "PeopleListSlider";
@import "CompanyListSlider";

// AVAILABLE PLATFORMS
.available-platforms-body {
  display: flex;
  align-items: center;
  gap: 24px;
  border-left: 8px solid $brand_color;
  background: #161616;
  border-radius: 0 0 8px 8px;
  padding: 16px 12px;

  @media screen and (min-width: 768px) {
    padding: 20px 24px;
  }

  .available-platforms-icon{
    flex-shrink: 0;
    font-size: 68px;
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
    }
  }

  .available-platforms-right{
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: hidden;
  }

  .available-platforms-texts{
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .available-platforms-title {
    color: $brand_color;
    font-weight: 700;
    font-size: size(20);
  }

  &--shortContent {
    border-left: none;
    border-radius: 12px;
    padding: 14px;
    .available-platforms-title {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

// WATCHLIST
.watchlist-button-overlay-body {
  position: absolute;
  top: 10px;
  display: flex;
  right: 10px;
  cursor: pointer;
}

.watchlist-button-overlay {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.watchlist-button-title {
  font-size: size(13);
  font-weight: 400;
  color: $text_color;
  white-space: nowrap;

  &[data-active=true] {
    color: $brand_color;
  }
}

// RATES
.rating-img {
  height: 20px;
  width: 40px;
  object-fit: contain;
}

.rating-text{
  font-size: size(12);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
  color: $text_color;
}

// LOADING
.loading-body {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, .5);
  z-index: 20;
}
