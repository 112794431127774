@import "Header";
@import "Footer";
@import "Profile";
@import "Error";

#main {
  position: relative;
  background-color: $background_color;
  flex: 1;
}

.app-container {
  &--home {
    @media screen and (min-width: 992px) {
      margin-top: -92px;
    }
  }
}
