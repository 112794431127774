.list-card {
    display: flex;
    flex-direction: column;
    background-color: #202020;
    border-radius: 28px;
    height: 100%;
    &__image {
        border-radius: 23px 23px 0 0;
        aspect-ratio: 16 / 9;
        object-fit: cover;
    }
    &-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 14px 20px 24px;
        &__title {
            color: white;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            transition: all 0.2s;
        }
        &__description {
            margin-top: 6px;
            color: #D2D2D2;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }
    &:hover {
        .list-card-content__title {
            color: $brand_color;
        }
    }
}

.list-end {
    display: flex;
    padding: 34px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 12px;
    border: 1px solid #E0E0E9;
    background: #202020;
}
