.search-page {
    &__backdrop {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -20px !important;
        padding-bottom: 64px;
        height: auto !important;
        .layout-profile__glass {
            width: 100%;
            max-width: 960px;
            margin: 0 24px;
            margin-top: 64px;
            backdrop-filter: blur(10px);
            text-align: center;
            padding: 40px 0 54px 0;
        }
    }
    &__type {
        color: #E7E7E7;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        @media screen and (max-width: 640px) {
            font-size: 14px;
        }
    }
    &__query {
        color: #FFF;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 60px;
        @media screen and (max-width: 640px) {
            font-size: 48px;
            margin-top: 12px;
        }
    }
    &__glassBottomText {
        color: #C2C2C2;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        @media screen and (max-width: 640px) {
            font-size: 14px;
            margin-top: 24px;
        }
    }
}
