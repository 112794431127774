.hoverable-content-poster {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 12px;

  &:hover {
    .content-poster-info {
      display: block;
    }

    -webkit-box-shadow: 1px 0px 15px 5px $bar_color, inset 0px 0px 10px rgba(255, 255, 255, 0.5);
    box-shadow: 1px 0px 15px 5px $bar_color, inset 0px 0px 10px rgba(255, 255, 255, 0.5);
  }
}

.content-poster-info {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 12px;
  padding: 10px;
}

.content-poster-info-mobile {
  gap: 4px;
  padding: 8px;
  background: #202020;
  border-radius: 0px 0px 8px 8px;
  .media-title, .genre-text {
    text-align: left;
  }
}

.media-title {
  text-align: center;
  width: 100%;
  font-size: size(13);
  font-weight: 600;
  color: $text_color;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: size(20);
  max-height: size(20);
  min-height: size(20);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.genre-text {
  text-align: center;
  width: 100%;
  font-weight: 400;
  font-size: size(12);
  line-height: size(16);
  color: $text_color;
  opacity: 0.7;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
