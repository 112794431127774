.checkbox-wrapper {    
    button {
        all: unset;
    }
    .CheckboxRoot {
        background-color: transparent;
        width: 18px;
        height: 18px;
        border: 1px solid #878787;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        transition: all 0.1s ease-in-out;
        &[data-state="checked"] {
            border: 1px solid #fff;
        }
        &:hover {
            background-color: #1a1a1a;
        }
        &:focus {
            box-shadow: 0 0 0 2px black;
        }
    }
      
    .CheckboxIndicator {
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
    }
    
    .Label {
        color: white;
        padding-left: 8px;
        font-size: 15px;
        line-height: 1;
        cursor: pointer;
    }

}

.checkbox-error {
    color: #F97066;
    font-size: 12px;
    margin-top: 8px;
}