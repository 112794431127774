$ad-zindex: 1;

.adsbygoogle {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.googlead-footer {
  z-index: $ad-zindex;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  max-height: 400px;
  width: 100%;
  margin: 20px 0 40px 0;
}

.googlead-home-top {
  z-index: $ad-zindex;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  max-height: 400px;
  width: 100%;
  height: 100%;
  margin: 20px 0 10px 0;
}

.googlead-side {
  z-index: $ad-zindex;
  overflow: hidden;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40vh;
  height: 610px;
  max-height: 610px;
  max-width: 310px;
  width: 100%;
}

.googlead-between-lists {
  z-index: $ad-zindex;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 500px;
  min-height: 300px;
}

.googlead-content {
  z-index: $ad-zindex;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 125px;
  max-height: 400px;
  height: 100%;
  width: 100%;
}

.googlead-tavsiye-listesi {
  z-index: $ad-zindex;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  max-height: 600px;
  width: 100%;
  height: 100%;
}
