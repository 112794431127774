.livetvHome {
    padding: 12px 0px;

    @media (min-width: 992px) {
        padding: 40px 0px;
    }

    &-title {
        font-size: size(25);
        font-weight: 700;
        line-height: size(32);
        color: #d9d9d9;
        letter-spacing: -0.75px;

        @media (min-width: 992px) {
            font-weight: 600;
            font-size: size(30);
            line-height: normal;
        }
    }

    &-section-label {
        font-weight: 600;
        font-size: size(16);
        text-transform: capitalize;
        color: $text_color;

        @media (min-width: 992px) {
            color: #D4D4D4;
            font-size: size(20);
        }
    }

    &-section-description {
        font-weight: 400;
        font-size: size(12);
        letter-spacing: -0.12px;
        color: #707070;
        overflow: hidden;
        margin-top: 4px;

        @media (min-width: 992px) {
            margin-top: 8px;
        }
    }

    &-categories {
        display: flex;
        flex-direction: column;
        gap: 32px;

        @media (min-width: 992px) {
            gap: 60px;
        }
    }

    &-genres {
        margin-top: 12px;

        @media (min-width: 992px) {
            margin-top: 24px;
        }

        &-list {
            margin-top: 12px;
            display: grid;
            grid-template-rows: auto auto;
            grid-auto-flow: column;
            gap: 8px;
            overflow-x: auto;

            @media (min-width: 992px) {
                margin-top: 18px;
                gap: 10px;
                display: flex;
                flex-wrap: wrap;
                overflow-x: hidden;
            }
        }

        &-item {
            display: flex;
            justify-content: center;
            border-radius: 59px;
            border: 1px solid #636363;
            color: #E7E7E7;
            padding: 12px 30px;
            font-size: size(12);
            white-space: nowrap;

            @media (min-width: 992px) {
                padding: 20px 36px;
                font-size: size(17.054);
            }

            &--active {
                color: $brand_color;
                border-color: $brand_color;
            }
        }

        &-item:hover {
            color: $brand_color;
            border-color: $brand_color;
        }
    }
}