// FOOTER
#footer {
  margin-top: auto;
}

.footer-inner {
  background-color: black;
}

.footer-title {
  font-size: size(12);
  font-weight: 600;
  color: $text_color;
  text-align: center;

  @media screen and (min-width: 992px) {
    font-size: size(14);
  }
}

.footer-description {
  font-size: size(12);
  letter-spacing: 0.39px;
  text-align: center;
  color: $text_color;
  margin-top: 40px;
  font-weight: 400;

  span {
    font-weight: bold;
  }

  @media screen and (min-width: 992px) {
    margin-top: 34px;
    font-size: size(14);
  }
}

.footer-mobileapp {
  margin-top: 40px;
  background-color: #202020;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 992px) {
    flex-direction: row;
    margin-top: 80px;
    border-radius: 40px;
  }

  .footer-mobileapp-content-wrapper {
    flex-shrink: 0;
    padding: 17px 24px 0px 25px;

    @media screen and (min-width: 992px) {
      padding: 78px 0px 103px 88px;
    }

    .footer-mobileapp-content {
      max-width: 460px;

      .footer-mobileapp-header {
        text-align: center;
        font-weight: 700;
        font-size: size(24);
        line-height: size(36);
        color: $text_color;
        margin-bottom: 0;

        @media screen and (min-width: 992px) {
          font-size: size(44);
          text-align: left;
          line-height: size(65);
        }
      }

      .footer-mobileapp-description {
        margin-top: 6px;
        text-align: center;
        font-weight: 400;
        font-size: size(14);
        line-height: size(22);
        color: #E0E0E0;

        @media screen and (min-width: 992px) {
          text-align: left;
        }
      }

      .footer-mobileapp-list {
        width: 100%;
        align-items: center;
        justify-content: center;
        display: inline-flex;
        flex-wrap: wrap;
        list-style: none;
        margin-top: 28px;
        padding: 0;
        gap: 20px;

        @media screen and (min-width: 992px) {
          justify-content: flex-start;
        }
      }

      .footer-mobileapp-list-item {
        display: inline-block;
        color: inherit;
        vertical-align: middle;
      }
    }
  }



  .footer-mobileapp-right-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;

    @media screen and (min-width: 992px) {
      justify-content: end;
      width: fit-content;
    }

    .footer-mobileapp-right {
      width: 100%;
      max-width: 460px;
      margin-left: 11px;
      margin-right: 13px;
      margin-bottom: -24px;

      @media screen and (min-width: 992px) {
        margin-left: 16px;
        margin-right: 8px;
        margin-top: -44px;
        margin-bottom: -20px;
        max-width: 586px;
      }

      .footer-mobileapp-image-wrapper {
        padding-bottom: calc(592 / 586 * 100%);
        position: relative;
      }

      .footer-mobileapp-image {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.inline-list {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.inline-list-item {
  display: inline-block;
  color: inherit;
  vertical-align: middle;
}

.footer-recentviews {
  margin-top: 40px;

  @media screen and (min-width: 992px) {
    margin-top: 80px;
  }

  .footer-recentviews-header {
    margin-bottom: 14px;

    @media screen and (min-width: 992px) {
      margin-bottom: 28px;
    }

    .footer-recentviews-header-left {
      gap: 10px;
    }
  }

  .footer-recentviews-title {
    color: #D4D4D4;
    font-size: size(14);

    @media screen and (min-width: 992px) {
      font-size: size(20);
    }
  }
}

.footer-registeralert {
  margin-top: 40px;
  color: $text_color;
  border-color: $brand_color;
  border-style: solid;
  border-width: 0 0 0 4px;

  font-size: size(14);
  line-height: size(30);
  font-weight: 600;

  padding: 8px 20px;

  @media screen and (min-width: 992px) {
    border-width: 0 0 0 10px;
    padding: 12px 24px;

    font-size: size(22);
    line-height: size(56);
    margin-top: 60px;
  }

  .footer-registeralert-highlight {
    font-weight: 700;
  }
}

.footer-links {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 40px;
  margin: 40px auto 0 auto;

  @media screen and (min-width: 768px) {
    max-width: 840px;
  }

  .footer-links-item {
    font-size: size(14);
    font-weight: 600;
    @media screen and (min-width: 992px) {
      font-size: size(16);
    }
  }
}

.footer-socialmedia {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .footer-socialmedia-title {
    color: $text_color;
    font-size: size(16);
    font-weight: 700;
  }

  .footer-socialmedia-links {
    margin-top: 12px;
  }
}
