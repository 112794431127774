.main-page-warning {
  color: $text_color;
  font-size: size(18);
  letter-spacing: 0.28px;
  font-weight: normal;

  .platform {
    font-size: size(18);
    font-weight: 500;
  }
}

.data-count {
  font-size: size(16);
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  color: $text_color;

  span {
    font-weight: 300;
    color: $text_color;
  }
}


.grid-card-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-flow: row;
  grid-gap: 40px;
  margin-top: 30px;
  padding-bottom: 50px;
}
