.slider-navigation-wrapper {
  position: relative;

  @media screen and (min-width: 992px) {
    &:hover {
      .slider-next-arrow, .slider-prev-arrow {
        display: flex;
      }
    }
  }
}

.content-slider-body {
  .keen-slider__slide {
    min-width: calc((100%) / 2.3 - 6px);
    max-width: calc((100%) / 2.3 - 6px);
    transform: translate3d(0, 0, 0);

    @media screen and (min-width: 480px) {
      min-width: calc((100%) / 3.5 - 8px);
      max-width: calc((100%) / 3.5 - 8px);

    }

    @media screen and (min-width: 992px) {
      min-width: calc((100%) / 5 - 12px);
      max-width: calc((100%) / 5 - 12px);
    }
  }
}


.slider-next-arrow, .slider-prev-arrow {
  transition: all .25s ease-out;

  &[data-disabled = true] {
    display: none !important;
  }

  display: none;
  cursor: pointer;
  background: rgba(20, 20, 20, .7);
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 20;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.slider-next-arrow {
  right: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.slider-prev-arrow {
  left: 0;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
