.genre-selection-body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-height: 390px;
  overflow-y: auto;
  padding-right: 10px;
}
.genre-selection-item {
  cursor: pointer;
  padding: 10px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  background-color: $background_color;
  font-size: size(14);
  line-height: size(19);
  font-weight: 400;
  color: $text-color;

  &[data-selected = true] {
    background-color: #070707;
    border-radius: 0 8px 8px 0;
    border-left: 8px solid $text_color;
    &:hover {
      border-radius: 0 8px 8px 0;
    }
  }

  &:hover {
    background-color: #070707;
    border-radius: 8px;
  }
}

