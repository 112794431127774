#error {
  padding-top: 50px;
  min-height: 80vh;

  .header {
    color: $text_color;
    font-size: 44px;
    font-weight: 800;
    white-space: nowrap;
    @media screen and (max-width: 992px) {
      font-size: 26px;
    }
  }

  .second-header {
    color: $text_color;
    font-size: 34px;
    font-weight: 700;
    white-space: nowrap;
    @media screen and (max-width: 992px) {
      font-size: 16px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    flex: 1;
    @media screen and (max-width: 992px) {
      margin-top: 40px;
    }
  }
}


