.absolute-roulette {
  margin-top: 100px;
  cursor: pointer;
  position: fixed;
  right: 0;
  background: $bar_color;
  box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.6);
  border-radius: 100px 0px 0px 100px;
  z-index: 1010;

  .close-button {
    z-index: 1;
    padding: 10px;
    color: $text_color;
    font-size: 13px;
    position: absolute;
    right: -5px;
    top: -10px;
  }
}

.absolute-roulette-mini {
  width: 83.87px;
  height: 79px;
  cursor: pointer;
  position: absolute;
  right: 0;

  background: $bar_color;
  box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.6);
  border-radius: 100px 0px 0px 100px;
  z-index: 1010;

}


.DialogOverlay.roulette-filter-overlay {
  background: rgba(0, 0, 0, 0.70);
}

.DialogContent.roulette-filter {
  padding: 40px 28px;
  border: 1px solid #37352F;
  background: #232323;

  display: flex;
  flex-direction: column;
  gap: 12px;

  .dialog-close-btn {
    top: 28px;
    right: 28px;
    background: transparent;
  }

  @media screen and (min-width: 768px) {
    max-width: 600px;
    border-radius: 18px;
  }
}

.type-selector-body {
  border-radius: 12px;
  background-color: $text_color;
  color: $background_color
}

.number-input-box {
  min-width: 62px;
  width: 62px;
  height: 36px;
  background: #353535;
  border-radius: 40px;
  outline: 0 none;
  border: none;

  font-weight: 400;
  font-size: size(14);
  line-height: size(24);
  text-align: center;
  color: $text_color;
  -moz-appearance: textfield;


  //hide arrow
  &::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.roulette-filter-segmented-control {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 12px;

  .black-button {
    padding: 6px 22px;

    &[data-selected=false] {
      background-color: #353535;
    }

    @media screen and (min-width: 768px) {
      padding: 6px 36px;
    }
  }
}

.roulette-filter-group-title {
  color: $text_color;
  font-size: size(14);
  line-height: size(24);
}

.roulette {
  padding-top: 16px;
  margin-bottom: 60px;

  @media screen and (min-width: 768px) {
    padding-top: 60px;
  }

  .roulette-header {
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-width: 485px;
    margin: 0 auto;
    text-align: left;
    color: $text_color;

    @media screen and (min-width: 768px) {
      text-align: center;
    }

    .roulette-header-title {
      font-weight: 700;
      font-size: size(25);
      line-height: size(32);

      @media screen and (min-width: 768px) {
        font-size: size(35);
        line-height: size(47);
      }
    }

    .roulette-header-description {
      font-size: size(14);
      line-height: size(22);
    }
  }

  .roulette-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .roulette-content-container-wrapper {
      padding: 0 0.75rem;
      width: 100%;
      margin-top: 18px;

      @media screen and (min-width: 768px) {
        margin-top: 70px;
      }

      .roulette-content-container {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 485px;
        width: 100%;
        border-radius: 20px;
        border: 1.218px solid rgba(255, 255, 255, 0.55);
        background: linear-gradient(108deg, rgba(255, 255, 255, 0.30) 7.87%, rgba(255, 255, 255, 0.28) 7.88%, rgba(255, 255, 255, 0.08) 97.59%);
        box-shadow: 2px 2px 14px 0px rgba(255, 255, 255, 0.08);

        @media screen and (min-width: 768px) {
          flex-direction: row;
          max-width: 882px;
          padding: 40px 60px;
          border: 1px solid #473717;
          background: #202020;
          gap: 28px;
        }

        .roulette-content-left {
          width: 100%;
          flex-shrink: 0;

          @media screen and (min-width: 768px) {
            width: 260px;
          }

          .media-picture{
            @media screen and (max-width: 768px) {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }

        .roulette-content-right {
          display: flex;
          flex-direction: column;
          padding: 6px 16px 14px 16px;

          @media screen and (min-width: 768px) {
            padding: 28px 0px;
          }

          .roulette-content-title {
            color: $text_color;
            font-size: size(19.376);
            font-weight: 700;

            @media screen and (min-width: 768px) {
              font-size: size(30);
              font-weight: 600;
            }
          }

          .roulette-content-description {
            margin-top: 4px;
            font-size: size(14);
            color: #d2d2d2;
            line-height: size(22);
            display: none;

            @media screen and (min-width: 768px) {
              display: inline;
            }
          }

          .roulette-content-bottom {
            flex: 1;
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
            gap: 8px;
            align-items: flex-start;

            @media screen and (min-width: 768px) {
              flex-direction: column;
              margin-top: 26px;
            }

            .roulette-content-ratings {
              .roulette-content-ratings-title {
                color: $brand_color;
                font-size: size(16);
                font-weight: 600;
                line-height: size(22);
                display: none;

                @media screen and (min-width: 768px) {
                  display: block;
                }
              }

              .roulette-content-ratings-list {
                @media screen and (min-width: 768px) {
                  margin-top: 12px;
                }

                &>div {
                  flex-wrap: wrap;
                }
              }
            }

            .roulette-content-button-wrapper {
              flex-shrink: 0;

              @media screen and (min-width: 768px) {
                margin-top: auto;
                width: 100%;
              }

              button {
                width: 100%;
                max-width: 260px;
                line-height: size(22);
                background-color: #141414;
                padding: 10px 12px;
              }
            }
          }
        }
      }
    }

    .roulette-spinnerv1-wrapper {
      width: 722px;
      height: 190px;
      mask-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);

      @media screen and (min-width: 768px) {
        margin-top: 49px;
        width: 1443px;
        height: 380px;
      }
    }
    
    .roulette-spinnerv2-container{
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 9999999;
      background: rgba(0,0,0,0.75);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 200ms opacity linear;
  
      .roulette-spinnerv2-wrapper {
        width: 350px;
        height: 350px;
      }
    }

    .roulette-body-buttons {
      margin-top: 22px;
      width: 100%;
      justify-content: center;
      padding: 0 8px;

      @media screen and (min-width: 768px) {
        margin-top: 70px;
      }

      button {
        width: 100%;
        max-width: 260px;

        @media screen and (max-width: 768px) {
          padding-left: 10px;
          padding-right: 10px;
        }

        svg {
          fill: none;
        }
      }
    }
  }
}