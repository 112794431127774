.home-backdrop {
  position: relative;
  width: 100%;
  min-height: 80vh;
  max-height: 1080px;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  transition: opacity .2s ease;
  @media screen and (max-height: 900px) {
    min-height: 90vh;
  }
  &::before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }
}

.hero-glass-card {
  width: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(102.47deg, rgba(255, 255, 255, 0.4) 0%, rgba(81, 81, 81, 0.2) 0%, rgba(186, 186, 186, 0.05) 98.96%);
  backdrop-filter: blur(17.5px);
  border-radius: 23px;
  gap: 40px;
  padding: 40px;
  max-height: min(90%, 770px);
  border: 1px solid rgba(170, 170, 170, 0.3);
  transition: opacity .2s ease;
  @media screen and (max-height: 900px) {
    padding: 20px 40px;
  }
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.hero-glass-card-mobile-body {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  justify-content: center;
  border-radius: 12px;
}

.hero-glass-card-mobile {
  background: linear-gradient(107.95deg, rgba(255, 255, 255, 0.3) 7.87%, rgba(255, 255, 255, 0.28) 7.88%, rgba(255, 255, 255, 0.08) 97.59%);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  //border: 1px solid rgba(255, 255, 255, 0.3);
  .hero-glass-title {
    font-size: size(14);
    line-height: size(19);
  }
}


.hero-glass-title {
  font-weight: 600;
  font-size: size(30);
  line-height: size(41);
  color: $text_color;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.hero-glass-description {
  font-weight: 400;
  font-size: size(14);
  line-height: size(22);
  color: #D2D2D2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.hero-selector-divider {
  width: 170px;
  background: #4B4B4B;
  height: 2px;
  margin: 20px 0;
  position: relative;

  .selector-divider-full {
    position: absolute;
    left: 0;
    top: 0;
    height: 2px;
    background: #FFFFFF;
    border-radius: 2px;
  }
}

.hero-selector {
  width: 170px;
  font-style: normal;
  font-weight: 700;
  font-size: size(32);
  line-height: size(40);
  color: $text_color;
  opacity: 0.3;
  cursor: pointer;

  &[data-selected = true] {
    opacity: 1;
  }
}

.hero-categories-title {
  color: $text_color;
  font-weight: 600;
  font-size: size(16);
  line-height: size(18);
}

.hero-categories {
  color: #D2D2D2;
  font-size: size(14);
  line-height: size(22);
}

// OTHERS
.home-stream-image {
  height: 45px;
  width: 45px;
  @media screen and (max-width: 992px) {
    height: 30px;
    width: 30px;
  }
}
.home-section-label {
  font-weight: 600;
  font-size: size(20);
  color: $text_color;
  text-transform: capitalize;
}

.home-section-description {
  font-weight: 400;
  font-size: size(14);
  line-height: size(20);
  letter-spacing: -0.14px;
  color: #707070;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.popular-titles-list {
  padding: 20px;
}

.home-genres-body {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 18px;
}

.home-genre {
  display: flex;
  justify-content: center;
  padding: 15px 30px;
  border-radius: 59px;
  border: 1px solid #636363;
  color: #E7E7E7;
  font-size: size(17);

  &:hover {
    border-color: $brand_color;
    color: $brand_color;
  }
}

.home-slider-body {
  .keen-slider__slide {
    min-width: calc((100%) / 1.5 - 8px);
    max-width: calc((100%) / 1.5 - 8px);
    transform: translate3d(0, 0, 0);

    @media screen and (min-width: 760px) {
      min-width: calc((100%) / 2 - 8px);
      max-width: calc((100% / 2) - 8px);
    }
  }
}
