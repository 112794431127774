
#header {
  color: $text_color;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
  filter: invert(1);
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: .3;
  pointer-events: all;
}

.navbar-custom {
  background-color: $nav_color;
  color: $text_color;

  .navbar-brand {
    font-size: size(20);
    font-weight: 600;
    letter-spacing: 0.39px;
  }

  .navbar-toggler {
    color: $text_color;
    border: none;
    padding: 2px 4px;
  }

  .navbar-toggler-icon {
    background-image: $navbar-custom-toggler-icon-bg;
  }

  .navbar-search-btn {
    color: $text_color;
    background: $search-color;
    padding: 0.5em;
    border-radius: 8px;
    border: none;
  }

  .navbar-search-btn-icon {
    display: inline-block;
    background-image: $navbar-search-btn-icon-bg;
    width: 1.25em;
    height: 1.25em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .nav-item {
    font-size: size(14);
    letter-spacing: 0.3px;
    white-space: nowrap;
  }

  .nav-link {
    display: flex;
    align-items: center;

    img, svg {
      margin-right: 5px;
    }
  }

  @media only screen and (max-width: 425px) {
    .navbar-brand {
      font-size: size(20);
      letter-spacing: 0.30px;
    }
  }
}

.header--hero {
  position: relative;
  z-index: 10;
  background-color: transparent;
  .navbar-custom {
    background-color: transparent;
  }
}

.header--default {
  background-color: $nav_color;
}

.search-container {
  flex: 1;
}

.custom-navbar-collapse {
  padding: 8px 0;
}
