$title-color: #D9D9D9;
$title-highlight: #FDB62F;
$overlay-bg: rgba(66, 66, 66, 0.4);
$dialog-bg: #02010C;
$hr-color: #444444;

.DialogOverlay {
  background-color: $overlay-bg;
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 9999;

}

.DialogContent {
  background-color: $dialog-bg;
  border-radius: 24px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 64px 28px 48px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 10000;
  overflow: auto;
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 0;
  }
}
.DialogContent:focus {
  outline: none;
}

.DialogTitle {
  max-width: 80%;
  margin: 0;
  font-weight: 700;
  color: $title-color;
  font-size: size(25);
}

span.text-highlight {
  color: $title-highlight;
}

.DialogDescription {
  margin: 10px 0 20px;
  color: var(--mauve11);
  font-size: 15px;
  line-height: 1.5;
}

.dialog-close-btn {
  position: absolute;
  top: 60px;
  right: 32px;
  width: 30px;
  height: 30px;
  background-color: $dialog-bg;
  border: none;
  color: $title-color;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.text-hr {
  max-width: 320px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  span:first-child {
    flex: 1;
    height: 1px;
    background-color: $hr-color;
  }
  span:nth-child(2) {
    margin: 0 10px;
    color: $hr-color;
  }
  span:last-child {
    flex: 1;
    height: 1px;
    background-color: $hr-color;
  }
}

.forgot-pass-link {
  color: #A4A4A4;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:hover {
    color: $title-highlight;
  }
}

.RateDialogContent {
  padding: 0;
  @media screen and (max-width: 768px) {
    bottom: 0;
    transform: translate(-50%, 0);
    border-radius: 0;
    height: unset;
    animation: rateDialogShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  .RateDialogHero {
    width: 100%;
    border-radius: 24px 24px 0 0;
    padding: 22px 26px;
    position: relative;
    background-size: contain;
    aspect-ratio: 16 / 9;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 24px 24px 0 0;
      width: 100%;
      height: 100%;
      content: '';
      background: rgba(0, 0, 0, 0.5);
    }
    &__glass {
      position: relative;
      border-radius: 24px;
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.40) 0%, rgba(81, 81, 81, 0.20) 0%, rgba(186, 186, 186, 0.05) 100%);
      backdrop-filter: blur(17.5px);
      padding: 18px;
      h6 {
        color: #FFF;
        font-size: size(18);
        font-weight: 600;
        line-height: 22px;
      }
      .dialog-close-btn {
        padding:0;
        top: 12px;
        right: 18px;
        width: auto;
        height: auto;
        background: transparent;
        color: #FFF;
      }
      .popcorn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          color: black;
          font-size: size(30);
          transform: translate(-50%, -50%);
          font-weight: 700;
          margin-top: 12px;
        }
      }
      button svg.text-gray {
        color: #8B8B8B;
      }
    }
  }

}


@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes rateDialogShow {
  from {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}



