.comments-warning{
  font-weight: 400;

  @media screen and (max-width: 768px) {
    font-size: size(11);
  }
}

.content-comment {
  margin-bottom: 2rem;
  width: 100%;
  .commentContent {
    &-desktop {
      display: block;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    &-mobile {
      display: none;
      @media screen and (max-width: 768px) {
        display: block;
      }
    }
  }
  div:last-child {
    width: 100%;
  }
  &__image {
    img {
      max-width: 70px;
      border-radius: 50%;
      border: 1px solid #fff;
    }
    margin-right: 40px;
    @media screen and (max-width: 768px) {
      margin-right: 24px;
    }
    &--poster {
      img {
        max-width: 100px;
        border-radius: 6px;
        border: none;
      }
    }
  }
  &__header {
    display: flex;
    align-items: center;
    span {
      margin-right: 14px;
      font-size: size(14);
      &:nth-child(1) {
        color: #fff;
      }
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      span {
        margin-bottom: 14px;
        &:last-child {
          margin-top: 2px;
        }
        &.separator {
          display: none;
        }
      }
    }
  }
  &__content {
    border-radius: 16px;
    border: 1px solid #D7D7D7;
    background: #232429;
    color: #FFF;
    font-size: size(14);
    line-height: 22px;
    padding: 20px;
    word-break: break-word;
  }
  &__feedback {
    font-style: italic;
    font-size: size(14);
    color: #A8A8A8;
    display: flex;
    align-items: center;
    div {
      display: flex;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
    button {
      margin-left: 24px;
      @media screen and (max-width: 768px) {
        margin-top: 12px;
        &:first-child {
          margin-left: 0;
        }
      }
      display: flex;
      align-items: center;
      svg {
        margin-right: 12px;
      }
      &:first-child {
        &.feedback-btn--active svg {
          fill: #FACA6F;
        }
      }
      &:last-child {
        svg {
          transform: rotate(180deg);
        }
        &.feedback-btn--active svg {
          fill: #F97066;
        }
      }
    }
  }
}
