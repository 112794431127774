.liveCategoryCard {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;

    &-header {
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1.057px solid #202020;
        border-right: 1.057px solid #202020;
        border-left: 1.057px solid #202020;
        border-radius: 8.458px 8.458px 0px 0px;
        background-color: black;
        padding: 20px 24px;
    }

    &-footer {
        flex-grow: 1;
        border-radius: 0px 0px 4px 4px;
        display: flex;
        gap: 10px;
        justify-content: center;
        background: #202020;
        align-items: center;
        padding: 16px 10px;
        color: $text_color;
        border: 1.057px solid transparent;
        border-top-width: 0;
    }

    &-title{
        text-align: center;
    }

    &--selected{
        .liveCategoryCard-header{
            border-color: $brand_color;
        }

        .liveCategoryCard-footer{
            border-color: $brand_color;
            color: $brand_color;
        }
    }
}