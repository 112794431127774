/* No-ui-slider */

.slim-slider {
  width: 100%;
  border: none;
  box-shadow: none;
  @include border-radius(0px);

  .noUi-handle {
    width: 16px !important;
    height: 16px !important;
    border-radius: 100%;
    box-shadow: none;
    background: $text_color;

    &:before {
      display: none;
    }

    &:after {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 100%;
      left: 3px;
      top: 3px;
      background: $text_color;
    }
  }

  &.noUi-horizontal {
    height: 3px;

    .noUi-handle {
      top: -7px;
      right: -8px;
    }
  }

  &.noUi-vertical {
    width: 0.25rem;
    height: 156px;
    display: inline-block;

    .noUi-handle {
      left: -7px;
    }
  }

  .noUi-base {
    box-shadow: none;
    border: none;
    background: #323232;

    .noUi-connect {
      background: $text_color;
      box-shadow: none;
    }

    .noUi-origin {
      background: $text_color;
    }
  }

  .noUi-pips {

    .noUi-marker-horizontal,
    .noUi-marker-vertical {
      background: $text_color;
    }

    .noUi-marker-horizontal {
      height: 9px;
      width: 1px;
    }

    .noUi-marker-vertical {
      height: 1px;
      width: 9px;
    }

    .noUi-value {
      color: $text_color;
      font-size: 0.94rem;
      font-weight: bold;
    }
  }
}

.thick-slider {
  width: 100%;
  border: none;
  box-shadow: none;

  &.noUi-horizontal {
    height: 10px;
    border-radius: 7px;

    .noUi-handle {
      width: 20px;
      height: 16px;
      top: -3px;
      right: -20px;

      &::after {
        height: 10px;
        width: 1px;
        background: $background_color;
        left: 7px;
        top: 1.5px;
      }

      &::before {
        height: 10px;
        width: 1px;
        background: $background_color;
        left: 11px;
        top: 1.5px;
      }
    }
  }

  &.noUi-vertical {
    .noUi-handle {
      left: -7px;
    }
  }

  &.noUi-target {
    background: #D9D9D9;
  }

  .noUi-connects {
    border-radius: 7px;
    .noUi-connect {
      background: $background_color;
    }
  }
}
