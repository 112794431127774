.media-picture-wrapper {
  height: 0;
  position: relative;
  display: flex;
  overflow: hidden;
  transition: opacity .1s ease;
}

.media-picture {
  width: 100%;
  height: 100%;
  position: absolute;
  background: url("/images/placeholder.png") darken($background_color, 10) no-repeat;
  background-size: cover;
  border-radius: 12px;
}

.aspect-ratio-10_15 {
  padding-bottom: calc(15 / 10 * 100%);
}

.aspect-ratio-16_9 {
  padding-bottom: calc(9 / 16 * 100%);  /* 1600:900 => width/height * 100*/
}

.aspect-ratio-4_3 {
  padding-bottom: calc(3 / 4 * 100%);  /* 1600:900 => width/height * 100*/
}

/* Adjust the iframe so it's rendered in the outer-width and outer-height of it's parent */
.aspect-ratio-iframe {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* The height of the item will now be 56.25% of the width. */
}

.video-iframe {
  outline: 0 none;
  border: 0 none;
  border-radius: 12px;
  position: absolute;
  width: 100%;
  height: 100%;
}

.absolute-youtube-icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 50px;
  height: auto;
  z-index: 10;
}

.backdrop-image {
  position: relative;
  margin-bottom: calc(100vw / -4);
  width: 100%;
  height: calc(100vw / 3);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  &::before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }
}

.container-fluid.container-max-width {
  max-width: calc($container-max-width + ($container-max-width-paddings * 2));
  padding-left: $container-max-width-paddings;
  padding-right: $container-max-width-paddings;
}

.hide-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.nopadding {
  padding: 0 !important;
}

.non-existence {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}

// custom scroll bar
.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1F1F1F;
    border-radius: 30px;

    &:hover {
      background: #1F1F1F;
    }
  }
}

.cursor-p {
  cursor: pointer;
}

.text-brand {
  color: $brand_color !important;
  svg {
    fill: $brand_color !important;
  }
}

.hover-scale {
  &:hover {
    transform: scale(1.1);
  }

  &--md {
    &:hover {
      transform: scale(1.05);
    }
  }
}

@media (max-width: 1050px) {
  .container-fluid.container-max-width {
    padding-left: $container-max-width-paddings-mobile;
    padding-right: $container-max-width-paddings-mobile;
  }
}
