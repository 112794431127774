.list-week-item-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  grid-gap: 40px;
}

.list-week-label {
  font-weight: 600;
  font-size: size(18);
  color: $text_color;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.list-week-mobile-body {
  padding: 14px;
  border-radius: 23px;
  border: 1px solid #404040;
  background: #202020;
  box-shadow: 3.544520616531372px 3.544520616531372px 16.541095733642578px 0px rgba(66, 66, 66, 0.25);
}

.list-week-content-title {
  color: #FEFEFE;
  text-align: center;
  font-size: size(14);
  line-height: size(24);
}
