.landscape-list-body {
  overflow-x: scroll;
  margin-top: 20px;
  width: 100%;
  display: -webkit-inline-box;
  display: -moz-inline-box;
}

.landscape-list-item {
  border-radius: 12px;
  display: block;
  position: relative;
  padding: 10px;

  &:hover {
    //.description {
    //  display: block;
    //  animation: description-anim 250ms forwards ease-in;
    //}
  }

  .description {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}

.portrait-list-body {
  overflow-x: scroll;
  margin-top: 15px;
  width: 100%;
  display: -webkit-inline-box;
  display: -moz-inline-box;
}

.portrait-list-item {
  border-radius: 12px;
  display: block;
  position: relative;
  padding: 10px;

  &:hover {
    //.description {
    //  display: block;
    //  animation: description-anim 250ms forwards ease-in;
    //}
  }

  .description {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes description-anim {
  0% {
    opacity: 0.5;
    max-height: 0%;
  }
  100% {
    opacity: 1;
    max-height: 100%;
  }
}

.list-title-shadow {
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  height: 180px;
  margin: 153.3px 0 0;
  padding: 20px 25px 20px 25px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.03), #000000 86%);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.list-text {
  font-size: size(20);
  font-weight: bold;
  letter-spacing: 0.09px;
  color: $text_color;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  //white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

/// DETAIL
.backdrop-title {
  font-size: size(40);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  color: $text_color;
}

.backdrop-description {
  margin-top: size(20);
  font-size: size(20);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  color: $text_color;
}

.list-page-backdrop {
  margin-bottom: 0 !important;
  height: auto !important;
  padding-bottom: 20px;
  &::before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: rgba(20, 20, 20, 0.4);
  }
  div {
    padding: 48px 0 64px 0;
  }
  h1 {
    color: #FFF;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  h2 {
    margin-top: 14px;
    color: #D7D7D7;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}


.list-detail-page-backdrop {
  margin-bottom: 0 !important;
  min-height: 400px;
  height: auto !important;
  &::before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: rgba(0, 0, 0, 0.3);
  }
  div {
    padding: 48px 0 64px 0;
  }
  h1 {
    color: #FFF;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  h2 {
    margin-top: 14px;
    color: #D7D7D7;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
  @media screen and (max-width: 780px) {
    margin-bottom: 0 !important;
  }
}

.list-content {
  margin-top: 0 !important;
  @media screen and (min-width: 992px) {
    margin-top: -150px !important;
  }
}

.list-left-buttons {
  display: flex;
  align-items: flex-start;
  gap: 24px;

  button {
    padding: 16px;
    border-radius: 12px;
    border: 1px solid #FFF;
    background: #232323;
    color: white;
    svg {
      fill: transparent;
    }
  }

  max-width: 768px;
  margin: 0 auto;

  @media screen and (min-width: 992px) {
    max-width: none;
    margin: 0;
    position: sticky;
    top: 256px;
    flex-direction: column;
  }
}
