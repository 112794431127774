.content-container {
    margin-top: 64px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.40) 0%, rgba(81, 81, 81, 0.20) 0%, rgba(186, 186, 186, 0.05) 100%);
    backdrop-filter: blur(17.5px);
    padding: 0 12px 0 0;
    border-radius: 16px 16px 0px 0px;
    width: 100%;
    position: relative;

    @media screen and (min-width: 768px) {
        border-radius: 24px 24px 0px 0px;
        padding: 0;
        background: linear-gradient(135deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.05) 100%);
        backdrop-filter: blur(40px);
    }

    &--shortContent {
        background: $bar_color;
        width: 100%;
        border-radius: 12px;
        margin-top: 0;
    }
}

.rate-button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: linear-gradient(44deg, rgba(81, 81, 81, 0.12) 0%, rgba(66, 66, 66, 0.08) 0%, rgba(111, 111, 111, 0.03) 100%);
    box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.07);
    border-radius: 0px 24px;
    padding: 17px 50px;
    color: $brand_color;
    font-weight: 700;
    align-items: center;
    z-index: 50;

    svg {
        margin-left: 8px;
    }

    span {
        font-size: size(14);
        color: rgba(255, 255, 255, 0.7);

        span {
            font-size: size(16);
        }
    }

    &--over-poster {
        justify-content: center;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0px 0px 24px 24px;
        background: linear-gradient(135deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.05) 100%);
        backdrop-filter: blur(5px);
    }

    display: none;

    &--over-poster {
        display: flex;
    }

    @media screen and (min-width: 768px) {
        display: flex;

        &--over-poster-sm {
            display: none;
        }
    }
}

.media-picture-detail {
    border-radius: 16px;

    @media screen and (min-width: 768px) {
        border-radius: 24px 0 0 0px;
    }
}

.content-title {
    width: 100%;
    font-size: size(30);
    font-weight: 600;
    color: $text_color;
    margin-bottom: 0.25em;
    overflow: hidden;
    text-overflow: ellipsis;
    //white-space: nowrap;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &--detail {
        padding-right: 0;

        @media screen and (min-width: 768px) {
            padding-right: 200px;
        }
    }

    &--shortContent {
        font-size: size(22);
    }
}

.content-title-original {
    font-size: size(16);
    line-height: size(22);
    font-weight: 400;
    color: #939393;
}

.clearfix:after,
.clearfix:before {
    content: " ";
    display: table;
}

.content-info-list{
    display: flex;
    column-gap: 24px;
    row-gap: 12px;
    align-items: flex-start;
    flex-wrap: wrap;

    @media screen and (min-width: 768px) {
       flex-wrap: nowrap;
    }
}

.content-info-item {
    font-size: size(14);
    max-width: fit-content;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.53px;
    color: #D2D2D2;
    min-width: 0;

    @media screen and (min-width:768px){
        flex:1;
    }

    span {
        font-weight: 400;
    }
}

.clips{
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding-top: 40px;

    @media screen and (min-width: 768px) {
        padding-top: 80px;
    }
}

.clips-clip{
    padding-bottom: 56.5%;
    position: relative;
}

.content-left-wrapper {
    padding-right: 0;
}

.content-right-wrapper {
    padding-left: 0;

    @media screen and (max-width: 768px) {
        padding-right: 0;
    }

    .content-info {
        padding-left: 24px;
        padding-right: 24px;

        @media screen and (min-width: 768px) {
            padding-left: 40px;
            padding-right: 40px;
            margin-top: 1.5rem;
        }
    }
}

.content-plot {
    font-size: size(14);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.53px;
    color: #d2d2d2;
    margin-bottom: 12px;
}

.content-rating-img {
    height: 25px;
    width: auto;
}

.content-rating-text {
    font-size: size(18);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.53px;
    color: $text_color;
}

.content-header-text {
    font-size: size(16);
    font-weight: 700;
    color: white;
}


.content-header-text2 {
    font-size: size(20);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.53px;
    color: $text_color;
}

.content-link {
    text-decoration: underline;

    &:hover {
        text-decoration: underline;
    }
}

.content-media-picture {
    padding: 14px 40px;

    @media screen and (min-width: 768px) {
        padding: 0;
    }
}

.content-title-wrapper {
    &--mobile {
        padding: 18px 12px;

        @media screen and (min-width: 768px) {
            display: none;
        }
    }

    &--desktop {
        display: none;
        padding-right: 0;

        @media screen and (min-width: 768px) {
            display: block;
        }
    }
}

.content-available {
    &--mobile {
        padding-left: 12px;
        margin-bottom: 1.25rem;

        .available-platforms-body {
            border-radius: 0;
            padding-left: 12px;
        }

        @media screen and (min-width: 768px) {
            display: none;
        }
    }

    &--desktop {
        display: none;

        @media screen and (min-width: 768px) {
            display: block;
        }
    }
}

.content-divider {
    flex-shrink: 0;
    display: none;
    height: 40px;
    width: 1px;
    background: #676767;
    transform: rotateZ(14deg);
    margin: 0 10px;

    @media screen and (min-width: 992px) {
        display: block;
    }
}

.content-section-title{
    font-size: size(16);
    font-weight: 700;
    color: $text_color;

    @media screen and (min-width: 768px) {
        font-size: size(20);
        line-height: size(22);
    }
}

.medias {
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-top: 40px;

    @media screen and (min-width: 768px) {
        margin-top: 22px;
    }
}

.seriesinfo {
    margin-top: 40px;

    @media screen and (min-width: 768px) {
        background-color: #1F1F1F;
        margin-top: 61px;
        padding-top: 40px;
    }

    &-container{
        display: flex;
        flex-direction: column;
        gap: 22px;
    }

    &-title-highlight{
        font-size: size(14);
        font-weight: 700;
        color: $brand_color;

        @media screen and (min-width: 768px) {
            font-size: size(16);
        }
    }

    &-seasonlist {
        @media screen and (min-width: 768px) {
            margin-bottom: 40px;
        }
    }

    &-season {
        background-color: #181818;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-radius: 4px;
        padding: 6px 6px 8px;
        border: 1px solid transparent;

        &:hover {
            background-color: #0E0E0E;
        }

        &--selected {
            border-color: $brand_color;
            background-color: #181818 !important;

                .seriesinfo-season-title{
                    color: $brand_color;
                }

                .seriesinfo-season-epcount{
                    color: #B99758;
                }
        }

        &-imagewrapper {
            border-radius: 2px;
            overflow: hidden;
        }

        &-texts {
            gap: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &-title {
            color: $text_color;
            font-size: size(17);
            font-weight: 600;
        }

        &-epcount {
            color: #D2D2D2;
            font-size: size(15);
            line-height: size(15);
        }
    }

    &-episodes {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 22px;

        &-listwrapper {
            @media screen and (min-width: 768px) {
                width: 618px;
            }
        }

        &-list {
            overflow: auto;
            display: flex;
            flex-direction: column;
            scrollbar-gutter: stable;

            @media screen and (min-width: 768px) {
                max-height: 500px;
                gap: 12px;
            }
        }
    }

    &-episode {
        border-radius: 12px;
        border: 1px solid transparent;

        @media screen and (min-width: 768px) {
            background-color: #181818;

            &:hover {
                border: 1px solid #474747;
            }

            &[data-state="open"] {
                border: 1px solid #474747;
            }
        }

        &-trigger {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            border: none;
            padding: 10px 0;
            width: 100%;
            background: transparent;
            color: #C9C9C9;
            text-align: left;
            font-size: size(14);
            font-weight: 600;
            line-height: size(22);
            border-bottom: 1px solid #303030;

            @media screen and (min-width: 768px) {
                font-size: size(18);
                line-height: size(16);
                padding: 24px 20px;
                border: none;
            }

            &[data-state="open"] {
                color: $brand_color;
            }

            &[data-state="open"] &-chevron {
                color: $brand_color;
                transform: rotateZ(180deg);
            }

            &-chevron {
                color: #C9C9C9;
            }
        }

        &-content {
            margin-top: 10px;
            gap: 8px;
            display: flex;
            flex-direction: column;

            @media screen and (min-width: 768px) {
                margin-top: 0;
                flex-direction: row;
                gap: 12px;
                padding: 0 20px 20px 20px;
            }
        }

        &-imagewrapper {
            flex-shrink: 0;
            border-radius: 6px;
            overflow: hidden;
            position: relative;

            @media screen and (min-width: 768px) {
                height: 100%;
                width: 284px;
            }

            img {
                object-fit: cover;
                object-position: center;
            }
        }

        &-texts {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            flex: 1;

            @media screen and (min-width: 768px) {
                flex-direction: column-reverse;
                justify-content: flex-end;
            }
        }

        &-meta {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (min-width: 768px) {
                justify-content: flex-end;
            }

            &-right {
                display: flex;
                align-items: center;
                gap: 8px;
                color: $brand_color;

                @media screen and (min-width: 768px) {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    padding: 6px 16px;
                    background-color: #202020;
                    border-top-left-radius: 6px;
                }

                &--mobile {
                    @media screen and (min-width: 768px) {
                        display: none;
                    }
                }

                &--desktop {
                    @media screen and (max-width: 767px) {
                        display: none;
                    }
                }
            }
        }

        &-right {
            padding: 15px 0;
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        &-rate {
            color: rgba(255, 255, 255, 0.70);
            font-size: size(14);
            line-height: size(22);
            font-weight: 600;
            letter-spacing: 0.42px;

            &-current {
                color: $text_color;
                font-size: size(16);
            }
        }

        &-description {
            margin-top: 6px;
            font-size: size(14);
            line-height: size(22);
            color: #F4F4F4;

            @media screen and (min-width: 768px) {
                margin-top: 4px;
            }
        }

        &-date {
            color: #B9B9B9;
            font-size: size(14);
            line-height: size(22);
            letter-spacing: 0.42px;

            @media screen and (min-width: 768px) {
                font-size: size(12);
                text-align: right;
                margin-top: auto;
                color: #E1E1E1;
            }
        }
    }
}

.suggestions {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;

    @media screen and (min-width: 768px) {
        margin-top: 80px;
    }
}
