$brand_color:        #FDB62F;
//$brand_color:        #F0B33E;
$bar_color:          #202020;
$nav_color:          #141414;
$background_color:   #141414;
$progress_color:     $brand_color;
$detail_color:       #202327;
$passive_color:      #7D7D7D;
//$passive_color:      #9b9b9b;
$text_color:         #ffffff;
$border-color:       #222222;
$border-color2:      #161616;
$search-color:       #262626;
$search-icon-color:  #878787;
$navbar-custom-color:                 rgba($text_color, 1) !default;
$navbar-search-btn-color:                 rgba($search-icon-color, 1) !default;
$navbar-custom-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-custom-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-search-btn-icon-bg:       url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z' stroke='#{$navbar-custom-color}' stroke-width='1.42857' stroke-linecap='round' stroke-linejoin='round'/></svg>") !default;


$container-max-width: 1280px;
$container-max-width-paddings: 64px;
$container-max-width-paddings-mobile: 16px;

// The default font size for html is 16px
$base-size: 16;
$font-size: 16px;


//@mixin random-bgr(){
//  background-color: rgb(random(255), random(255), random(255));
//}
//@for $i from 0 to $num-colors {
//  &:nth-child(#{$i}) {
//    @include random-bgr();
//  }
//}
