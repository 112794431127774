.ntoast {
  --factor: 1;
  width: 100%;
  padding: 16px 18px 16px 8px;
  font-size: 14px;
  font-weight: 600;
  border-left: 10px solid #ffa700;
  border-radius: 4px;
  color: #141414;
  background-color: #ffca64;

  display: flex;
  align-items: center;

  @media screen and (min-width: 768px) {
    padding-left: 12px;
    max-width: 444px;
    width: fit-content;
  }

  &--with-icon {
    gap: 12px;

    @media screen and (min-width: 768px) {
      gap: 6px;
    }
  }

  &--error {
    background-color: #ff6464;
    border-left-color: #ff0000;
  }

  .ntoast-icon {
    flex-shrink: 0;
  }

  .ntoast-content {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 16px;

    @media screen and (min-width: 768px) {
      gap: 24px;
    }

    .ntoast-action {
      font-weight: 700;
      font-size: 18px;
      flex-shrink: 0;
      margin-left: auto;
      transition: 300ms all;
      color: #141414;
  
      @media screen and (min-width: 768px) {
        margin-left: 0;
      }
  
      &:hover {
        color: white;
      }
    }
  }
}

@keyframes enterToastAnimation {
  0% {
    transform: translate3d(0, calc(var(--factor) * -200%), 0) scale(.6);
    opacity: .5;
  }

  100% {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
}

@keyframes exitToastAnimation {
  0% {
    transform: translate3d(0, 0, -1px) scale(1);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, calc(var(--factor) * -150%), -1px) scale(.6);
    opacity: 0;
  }
}