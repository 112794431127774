.recentviews {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &-footer {
        gap: 14px;
        margin-top: 40px;

        @media screen and (min-width: 992px) {
            margin-top: 80px;
            gap: 28px;
        }

        .recentviews-title{
            font-weight: 700;
            font-size: size(14);

            @media screen and (min-width: 992px) {
                font-size: size(20);
            }
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;

        &-left {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    &-title {
        color: $text_color;
        font-size: size(16);
        line-height: size(24);
        font-weight: 600;

        @media screen and (min-width: 992px) {
            font-size: size(14);
        }
    }
}
