.channelLogo-body {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    user-select: none;
    width: 45px;
    height: 45px;
    position: relative;

    .channelLogo-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
    }

    .channelLogo-fallback {
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text_color;
        font-size: size(16);
        line-height: 1;
        font-weight: 700;

        .channelLogo-fallback-text {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

}