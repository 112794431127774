// FILTER
.filter-bar {
  background-color: $bar_color;
}

.platform-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 8px;
  opacity: 0.2;

  &[data-active = true] {
    opacity: 1;
  }
}

.filter-dialog-content {
  @media screen and (max-width: 768px) {
    padding: 30px 20px 48px 20px;
    width: 90vw;
    height: 80vh;
    border-radius: 24px;
  }

  .dialog-close-btn {
    top: 26px;
  }
}
