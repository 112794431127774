.notifs-toggle {
    background-color: #25272E;
    border: none;
    padding: 8px 16px;
    border-radius: 12px;
    text-align: left;
    &__label {
        div:first-child {
            font-size: size(12);
            color: #6D6D6D;
        }
        div:last-child {
            font-size: size(14);
            color: #C2C3CB;
        }
    }
}