.livetvChannel{
    padding: 12px 0px;

    @media (min-width: 992px) {
        padding: 30px 0px;
    }

    &-header {
        display: none;
        align-items: center;
        gap: 14px;

        @media (min-width: 992px) {
            display: flex;
        }

        &-title {
            color: $text_color;
            font-size: size(30);
            font-weight: 600;
        }
    }

    &-main {
        display: flex;
        flex-direction: column  ;
        align-items: stretch;
        gap: 12px;

        @media (min-width: 992px) {
            margin-top: 30px;
            align-items: flex-start;
            flex-direction: row;
        }

        &-player-wrapper{
            flex: 841;
        }

        &-info {
            flex: 400;
            display: flex;
            gap: 12px;
            padding: 10px 15px;
            align-items: center;
            border-radius: 6px;
            border: 1px solid rgba(65, 65, 65, 0.50);
            background: #202020;

            @media (min-width: 992px) {
                padding: 16.5px 20px;
            }

            &-right {
                &-title {
                    color: $text_color;
                    font-size: size(14);
                    font-weight: 600;
                    line-height: size(24);
                    letter-spacing: 0.14px;
                }

                &-description {
                    margin-top: -2px;
                    font-size: size(12);
                    font-weight: 400;
                    line-height: size(24);
                }
            }
        }
    }

    &-footer {
        margin-top: 40px;
    }

    &-video-custom-error {
        background-color: black;
        z-index: 6;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;

        @media (min-width: 992px) {
            gap: 20px;
        }

        p {
            line-height: normal;
            font-weight: 400;
            color: #8E8E8E;
            font-size: size(12);
            letter-spacing: -0.12px;
            max-width: 257px;
            text-align: center;

            @media (min-width: 992px) {
                font-size: size(18);
                letter-spacing: -0.18px;
                max-width: 372px;
            }
        }
    }

    &-video-topleft {
        position: absolute;
        z-index: 10;
        top: 15px;
        left: 15px;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: size(18);
        font-weight: 600;

        &-image{
            @media (max-width: 992px) {
                display: none !important;
            } 
        }
    }

    &-video-topright {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        pointer-events: none;
        z-index: 10;

        .livetvChannel-video-topright-open {
            padding: 10px 38px;
            pointer-events: all;
            height: 40px;
            background-color: $brand_color;
            color: #3D2D11;
            font-size: 12px;
            font-weight: 600;
            margin: 15px 15px;
            border-radius: 6px;
        }

        &-menu {
            position: absolute;
            pointer-events: none;
            display: none;
            top: 0;
            right: 0;
            width: 264px;
            height: 100%;
            z-index: 10;
            background-color: #141414;

            &-header {
                padding: 15px 15px 6px 15px;

                &-top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }

            &-title {
                font-size: size(14);
                font-weight: 700;
                color: $brand_color;
                line-height: size(32);
                letter-spacing: -0.42px;
            }

            .livetvChannel-video-topright-menu-item {
                gap: 12px;
                padding: 10px 15px;
                background-color: #232323;
                width: 100%;
                border: 1px solid rgba(65, 65, 65, 0.50);
                border-radius: 6px;
                display: flex;
                flex-direction: row;
                color: $text_color;

                p {
                    font-size: size(14);
                    line-height: size(24);
                    letter-spacing: 0.14px;
                    font-weight: 600;
                }
            }

            .livetvChannel-video-topright-menu-item--active {
                color: $brand_color;
                border-color: $brand_color;
            }

            &-list {
                overflow: auto;
                background-color: #1A1A1A;
                flex: 1;
                padding: 6px 15px;
                display: flex;
                flex-direction: column;
                gap: 6px;
            }
        }


        &-menu--open {
            pointer-events: all;
            display: flex;
            flex-direction: column;
        }
    }
}