.liveCategoryList {
    &-label {
        font-weight: 600;
        font-size: size(16);
        text-transform: capitalize;
        color: $text_color;

        @media (min-width: 992px) {
            color: #D4D4D4;
            font-size: size(20);
        }
    }

    &-description {
        font-weight: 400;
        font-size: size(12);
        letter-spacing: -0.12px;
        color: #707070;
        overflow: hidden;
        margin-top: 4px;

        @media (min-width: 992px) {
            margin-top: 8px;
        }
    }
    &-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
        gap: 7px;


        @media (min-width: 992px) {
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
            gap: 40px;
        }
    }
}