@media (max-width: 992px) {
  .hero-selector {
    width: unset;
    font-size: size(25);
    margin-bottom: 10px;
  }
  .hero-selector {
    width: unset;
    font-size: size(25);
    margin-bottom: 10px;
  }
  .bookmark-card-list {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 32px;
    grid-column-gap: 59px;
  }

  .backdrop-image {
    margin-bottom: -200px;
    height: 400px;
  }

  .progress-container {
    display: none;
  }
  .home-genres-body {
    display: grid;
    gap: 8px;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    overflow-x: scroll;
    margin-top: 12px;
    white-space: nowrap;
  }

  .home-genre {
    padding: 10px 25px;
    font-size: size(12);
  }
}

@media (max-width: 780px) {
  .hoverable-content-poster {
    border-radius: 12px 12px 0 0;
  }

  .hoverable-content-poster {
    &:hover {
      .content-poster-info {
        display: none;
      }

      -webkit-box-shadow: unset;
      box-shadow: unset;
    }
  }

  .backdrop-title {
    font-size: size(25);
  }

  .backdrop-description {
    font-size: size(12);
  }

  .grid-card-list {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 15px;
  }

  .main-page-warning {
    font-size: size(12);

    .platform {
      font-size: size(12);
    }
  }

  .backdrop-image {
    margin-bottom: -200px;
    height: 200px;
  }

  .search-result-body {
    width: calc(100vw - 30px);
    max-width: unset;
  }

  .main-title {
    font-size: size(24);
  }

  .portrait-list-item {
    width: 200px;
  }

  .list-text {
    font-size: size(16);
  }
}

@media (max-width: 430px) {
  .user-list-item {
    width: 320px;
  }

  .black-button {
    padding: 4px 16px;
    gap: 8px;
    font-size: size(12);

    img {
      margin-left: 2px;
    }
  }

  .bookmark-description {
    -webkit-line-clamp: 2;
  }

  .data-count {
    span {
      font-size: size(12);
    }
  }
}


@media (max-width: 380px) {
  .grid-card-list {
    grid-template-columns: repeat(auto-fill, minmax(136px, 1fr));
    grid-gap: 10px;
  }
}

@media (max-width: 330px) {
  html, body {
    font-size: 12px !important;
  }
}
