.input-custom {
    input, textarea {
        padding: 16px;
        gap: 10px;
        width: 100%;
        background: #25272E;
        border-radius: 12px;
        border: none;
        font-size: 14px;
        transition: all 250ms;
        border: 1px solid transparent;
        &:focus-within {
            outline: none;
            border: 1px solid #4A4C59;
            & ~ .input-custom__label {
                font-size: size(12);
                top: 8px;
                transform: translateY(0);
            }
        }
    }


    input:not(:placeholder-shown) {
        & ~ .input-custom__label {
            font-size: size(12);
            top: 8px;
            transform: translateY(0);
        }
    }

    &--withLabel {
        input {
            padding-top: 24px;
            padding-bottom: 12px;
        }
    }

    &--withIcon {
        input {
            padding-left: 52px;
        }
        svg {
            color: #6D6D6D;
        }
    }

    &--error input, &--error textarea {
        border: 1px solid #F97066;
        border-radius: 12px;
    }

    &__error {
        color: #F97066;
        font-size: 12px;
        margin-top: 8px;
        padding-left: 16px;
    }

    &__label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        font-size: size(14);
        left: 52px;
        color: #6D6D6D;
        transition: all 0.2s ease-in-out;
    }

    .password-toggle-btn {
        background: transparent;
        border: 0;
    }
}

.textarea-custom {
    label {
        color: #989898;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 8px;
    }
    &__error {
        margin-top: 4px;
        padding-left: 0;
    }
}
