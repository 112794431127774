.layout-profile {
    margin: 0;
    &__hero {
        width: 100%;
        padding: 0;
        & > img {
            width: 100%;
            height: 350px;
            object-fit: cover;
        }
    }
    .back-btn {
        position: absolute;
        top: 12px;
        left: 12px;
        background: transparent;
        border: none;
    }
    &__hero-over {
        top: 64px;

        @media screen and (min-width: 768px) {
            top: 0;
        }
    }
    &__glass {
        background: linear-gradient(102.47deg, rgba(255, 255, 255, 0.4) 0%, rgba(81, 81, 81, 0.2) 0%, rgba(186, 186, 186, 0.05) 98.96%);
        backdrop-filter: blur(17.5px);
        padding: 40px;
        border-radius: 24px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        font-size: 14px;
    }
    &__sidebar {
        display: none;
        width: calc((100vw - 1440px)/2);
        @media screen and (min-width: 768px) {
            min-width: 350px;
            display: block;
            position: absolute;
            top:0;
            background: linear-gradient(102.47deg, rgba(255, 255, 255, 0.4) 0%, rgba(81, 81, 81, 0.2) 0%, rgba(186, 186, 186, 0.05) 98.96%);
            backdrop-filter: blur(17.5px);
            height: 100%;
            padding: 250px 24px 48px;
        }
        @media screen and (min-width: 1024px) {
            min-width: 410px;
            padding: 250px 48px 48px;
        }
    }
    &__content {
        padding-top: 192px;
        padding-bottom: 48px;
        min-height: 420px;

        .max-w-sm {
            max-width: 100%;
            @media screen and (min-width: 768px) {
                max-width: 480px;
            }
        }

        @media screen and (min-width: 576px) {
            padding-top: 128px;
        }
        @media screen and (min-width: 768px) {
            padding-top: 48px;
        }
    }
    &__children {
        margin-left: -$container-max-width-paddings-mobile;
        margin-right: -$container-max-width-paddings-mobile;
        padding-right: max($container-max-width-paddings, calc((100vw - $container-max-width) / 2)); // container_max_width
        padding-left: calc((100vw - $container-max-width) / 2);
      @media screen and (max-width: 1050px) {
          padding-right: $container-max-width-paddings-mobile;
          padding-left: $container-max-width-paddings-mobile;
      }
      @media screen and (max-width: 2260px) and (min-width: 768px) {
          padding-left: calc(350px + $container-max-width-paddings);  // sidebar + container_max_width_padding (350 + $container-max-width-paddings)
      }

      @media screen and (max-width: 2260px) and  (min-width: 1024px){
          padding-left: calc(410px + $container-max-width-paddings); // sidebar + container_max_width_padding (410 + 64)
      }
    }
}
