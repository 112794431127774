.video-js {

  .livetvChannel-video-topleft,
  .livetvChannel-video-topright {
    display: none;
  }
}

// For mobile: If video is paused or user is active on video show top left section
// For web: same for mobile but only on fullscreen
.video-js.vjs-paused,
.video-js.vjs-user-active {
  .livetvChannel-video-topleft {
    display: flex;

    @media (min-width: 992px) {
      display: none;
    }
  }

  &.vjs-fullscreen {
    .livetvChannel-video-topleft {
      @media (min-width: 992px) {
        display: flex;
      }
    }
  }
}

// If video is fullscreen and (user is active or video is paused)
// or there is an error show top right section
.video-js.vjs-user-active.vjs-fullscreen,
.video-js.vjs-paused.vjs-fullscreen,
.video-js.vjs-error {
  .livetvChannel-video-topright {
    display: flex
  }
}

.video-js {
  .vjs-error-display {
    display: none;
  }
}

.video-js.vjs-theme-nei {
  min-height: 260px;

  .vjs-custom-error {
    background-color: black;
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    @media (min-width: 992px) {
      gap: 20px;
    }

    p {
      line-height: normal;
      font-weight: 400;
      color: #8E8E8E;
      font-size: size(12);
      letter-spacing: -0.12px;
      max-width: 257px;
      text-align: center;

      @media (min-width: 992px) {
        font-size: size(18);
        letter-spacing: -0.18px;
        max-width: 372px;
      }
    }
  }

  .vjs-control-bar {
    width: calc(100% + 1px);
    height: 70px;
    padding-top: 20px;
    background: none;
    background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
  }

  .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .vjs-seek-to-live-control {
    position: absolute;
    bottom: 60px;
    height: 20px;
    left: 16px;

    .vjs-seek-to-live-text {
      &::before {
        content: "Canlı";
        font-size: size(14);
        font-weight: 600;
        color: #888;
        visibility: visible;
      }

      visibility: hidden;
    }
  }

  .vjs-seek-to-live-control.vjs-control.vjs-at-live-edge {
    .vjs-icon-placeholder {
      color: $brand_color;
    }

    .vjs-seek-to-live-text {
      &::before {
        color: $brand_color;
      }
    }
  }

  .vjs-button>.vjs-icon-placeholder::before {
    line-height: 50px;
  }

  .vjs-play-progress::before {
    display: none;
  }

  .vjs-progress-control {
    position: absolute;
    top: 0;
    right: 16px;
    left: 16px;
    width: calc(100% - 32px);
    height: 20px;
  }

  .vjs-progress-control .vjs-progress-holder {
    position: absolute;
    top: 20px;
    right: 0;
    left: 0;
    width: 100%;
    margin: 0;
  }

  .vjs-play-progress {
    background-color: $brand_color;
  }

  .vjs-remaining-time {
    order: 1;
    line-height: 50px;
    flex: 3;
    text-align: left;
  }

  .vjs-picture-in-picture-control {
    margin-left: auto;
  }

  .vjs-volume-panel {
    align-items: center;
    padding-top: 0;

    .vjs-volume-control.vjs-volume-horizontal {
      height: 3em;
      transition: visibility 1s, opacity 1s, width 1s, left 1s 1s, top 1s 1s;
    }
  }
}