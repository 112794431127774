.custom-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 12px;
  gap: 10px;
  border-radius: 10px;
  font-size: size(12);
  outline: none;
  border: 1px solid $brand_color;
  transition: all 250ms;
  font-weight: 600;

  &--md {
    padding: 14px 28px;
    font-size: size(14);
  }

  &--lg {
    padding: 19px 28px;
    font-size: size(16);
  }

  &:disabled {
    svg {
      fill: #6E7066;
    }

    border: 1px solid #6E7066;
    background: #6E7066;
    color: #A0A0A0;
  }
}

.primary-btn {
  color: #3D2D11;
  background: $brand_color;

  svg {
    fill: $brand_color;
  }

  &:hover:enabled {
    background: #FACA6F;

    svg {
      fill: #FACA6F;
    }
  }

  &:active:enabled {
    background: #EEA51A;

    svg {
      fill: #EEA51A;
    }
  }
}

.primary-outline-btn {
  border: 1px solid $brand_color;
  color: $brand_color;
  background: transparent;

  svg {
    fill: $brand_color;
  }

  &:hover:enabled {
    border-color: #FACA6F;
    color: #FACA6F;

    svg {
      fill: #FACA6F;
    }
  }

  &:active:enabled {
    border-color: #EEA51A;
    color: #EEA51A;

    svg {
      fill: #EEA51A;
    }
  }
}

.outline-btn {
  border: 1px solid #25272E;
  color: $text_color;
  background: transparent;

  svg {
    fill: $brand_color;
  }

  &:hover:enabled {
    border-color: #FACA6F;
    color: #FACA6F;

    svg {
      fill: #FACA6F;
    }
  }

  &:active:enabled {
    border-color: #EEA51A;
    color: #EEA51A;

    svg {
      fill: #EEA51A;
    }
  }
}

.white-btn {
  color: #4D4D4D;
  background: white;
  border: 1px solid white;
  &:hover {
    background: #FFFFFFE5;
    border: 1px solid #FFFFFFE5;
  }
}

.black-btn {
  color: $text_color;
  background: $background_color;
  border: 1px solid $background_color;
  &:hover {
    background: #141414E0;
    border: 1px solid #141414E0;
  }
}

.unstyled-btn {
  background: transparent;
  border: none;
  padding: 0;
  justify-content: flex-start;
  color: #989898;

  svg {
    fill: #989898;
  }

  &:hover:enabled {
    color: #dcdcdc;

    svg {
      fill: #dcdcdc;
    }
  }

  &:active:enabled {
    color: #dcdcdc;

    svg {
      fill: #dcdcdc;
    }
  }
}

.black-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 18px;
  gap: 10px;
  border-radius: 40px;
  font-size: size(14);
  color: $text_color;
  outline: none;
  border: none;

  &[data-selected = true] {
    background: $background_color;
  }

  img {
    margin-left: 5px;
  }

  &:hover {
    color: $brand_color;

    svg {
      fill: $brand_color;
    }
  }
}
