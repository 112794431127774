.people-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  min-width: 90px;
  max-width: 90px;
}

.content-people-image {
  border-radius: 50%;
  object-position: center;
  object-fit: cover;

  @media screen and (min-width: 768px) {
    width: 60px;
    height: 60px;
  }
}

.people-list-name {
  color: $text_color;
  font-size: size(12);
  line-height: size(12);

  @media screen and (min-width: 768px) {
    font-size: size(12);
    font-weight: 600;
    line-height: size(15);
  }
}

.people-list-role {
  display: none;
  color: #BABABA;
  font-size: size(12);
  line-height: size(14);

  @media screen and (min-width: 768px) {
    display: block;
  }
}
