.company-list-item {
  min-width: 130px;
  max-width: 130px;
}

.company-list-imagewrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 3px;
  position: relative;
  height: 70px;
  background-color: #D9D9DE;
}

.company-list-image {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}

.company-list-name {
  text-align: center;
  display: none;
  color: #C9C9C9;
  font-size: size(12);
  font-weight: 600;
  line-height: size(18);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    display: -webkit-box;
  }
}
