.companyLogo-body {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    user-select: none;
    width: 100%;
    height: 100%;
    position: relative;

    .companyLogo-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
    }

    .companyLogo-fallback {
        text-align: center;
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #383838;
        font-size: size(16);
        line-height: size(16);
        font-weight: 700;

        .companyLogo-fallback-text {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }
    }

}