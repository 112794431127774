@import "variables";

/* === FONT FAMILY === */
//@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

/* === BOOTSTRAP === */
// Bootstrap variable overrides
$enable-shadows: true;
$hr-border-color: $border-color2;

@import "~bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/functions";

/* === LIBRARIES === */
@import "~nouislider/distribute/nouislider.css";
@import '~compass-mixins/lib/compass/css3';
@import '~keen-slider/keen-slider.min.css';
@import "~video.js/dist/video-js.css";

/* === CUSTOM === */

//Function to convert px value into rem
//You can replace rem with any other value you want (rem is most preferred value for web)
@function size($target, $context: $base-size) {
  @return ($target / $context) * 1rem;
}

/* === BOOTSTRAP === */

@import "common";
@import "layout";
@import "googleAds";
@import "pages";
@import "components";
@import "libraries";
@import "media"; // must be last

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-open-sans), -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background_color;
  font-size: $font-size;
  height: 100%;
}

a {
  cursor: pointer;
  color: $text_color;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $brand_color;
  }
}

* {
  box-sizing: border-box;
}

p, h1, h2, h3, h4, h5 {
  margin-top: 0;
  margin-bottom: 0;
}

ol, ul {
  list-style: none;
}

hr {
  width: 100%;
  opacity: 1;
}

.vertical-hr {
  border: none;
  border-left: 1px solid $hr-border-color;
  width: 1px;
  margin: 0 1rem;
}

#__next {
  min-height: 100vh !important;
  flex: 1;
  flex-flow: column;
  display: flex;
}

// bootstrap css variable override
[data-bs-theme=dark] {
  .navbar-nav {
    --bs-nav-link-color: $text_color;
    --bs-nav-link-hover-color: #FDB62F !important;
  }

}
