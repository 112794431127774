.header-search {
  position: relative;
}

.header-search-body {
  display: flex;
  flex-direction: column;
  gap: 16px;

  hr {
    margin: 0;
    border-top-color: #404146;
  }

  .header-search-recentsearches {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .header-search-recentsearches-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header-search-recentsearches-title {
        color: #c0c0c0;
        font-size: size(16);
        line-height: size(24);
        font-weight: 400;

        @media screen and (min-width: 992px) {
          font-size: size(14);
        }
      }
    }

    .header-search-recentsearches-list {
      display: flex;
      flex-wrap: wrap;
      row-gap: 12px;
      column-gap: 6px;

      margin: 0;
      padding: 0;

      .header-search-recentsearches-item {
        cursor: pointer;
        background-color: #404146;
        color: white;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 4px 16px;
        border-radius: 40px;
        font-size: size(12);
        line-height: size(24);
        font-weight: 600;

        @media screen and (min-width: 992px) {
          font-size: size(14);
          padding: 6px 18px;
        }
      }
    }
  }

  .header-search-recentviews {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .header-search-recentviews-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header-search-recentviews-title {
        color: #c0c0c0;
        font-size: size(16);
        line-height: size(24);
        font-weight: 400;

        @media screen and (min-width: 992px) {
          font-size: size(14);
        }
      }
    }
  }

  .header-search-popular-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-search-popular-title {
      color: $text_color;
      font-size: size(16);
      line-height: size(24);
      font-weight: 600;

      @media screen and (min-width: 992px) {
        font-size: size(14);
      }
    }
  }

  .header-search-result {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .header-search-result-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header-search-result-title {
        color: #c0c0c0;
        font-size: size(16);
        line-height: size(24);
        font-weight: 400;

        @media screen and (min-width: 992px) {
          font-size: size(14);
        }
      }
    }

    .header-search-result-list {
      display: flex;
      flex-direction: column;

      margin: 0;
      padding: 0;
    }

    .header-search-result-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 5px 5px 5px;
      gap: 12px;
      border-radius: 4px;
      color: $text_color;

      &:first-child {
        border-top: none;
      }

      &:hover {
        background-color: #202020;
        color: $brand_color;
      }

      img {
        flex-shrink: 0;
        width: 50px;
        height: 75px;
        border-radius: 4px;
      }

      p {
        font-size: size(14);
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: size(24);
        letter-spacing: 0.22px;
      }

      span {
        font-size: size(12);
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        color: #C0C0C0;
      }
    }
  }

  .header-search-more {
    font-size: size(14);
    line-height: size(24);
  }
}
