.user-list-body {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.user-list-item {
  border-radius: 20px;
  border: 1px solid #404040;
  background: #202020;
  padding: 12px;
  width: 365px;
  height: 174px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-list-title {
  color: $text_color;
  font-size: size(16);
  font-weight: 600;
  line-height: size(24);
}

.user-list-description {
  color: #CACACA;
  font-size: size(14);
  line-height: size(20);
}

.user-list-content-count {
  color: #CACACA;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.selectable-user-list-item {
  border-radius: 12px;
  background: #FFF;
  width: 100%;
  color: #202020;
  font-size: size(16);
  font-weight: 600;
  line-height: size(18);
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 20px;
  &[data-selected = true] {
    background: $brand_color;
  }
}
